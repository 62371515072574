import LargeForm from 'components/forms/LargeForm'
import Head from 'components/global/Head'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import { graphql } from 'gatsby'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Layout from '../components/Layout'
import ModuleListItem from '../components/themes/template-components/ModuleListItem'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

interface Props {
	data: ThemeQuery
}

export default function ThemeTextSetTemplate({ data: themeData }: Props) {
	const data = themeData.contentfulTheme

	return (
		<Layout>
			<Head title={`${data.name} | ARC Core Theme`} description={data.description.description} image={data.coverImage.file.url} />
			<Section>
				<Row className="mt-10 items-center">
					<Col width="w-full md:w-2/3" className="md:pl-12">
						<H1 className="mb-3">{data.logo ? <img src={data.logo.file.url} alt={`${data.logo.title} Logo`} style={{ maxHeight: 150 }} /> : data.name}</H1>
						<P className="mb-3">{data.description ? data.description.description : 'No description for this theme.'}</P>
						<span className="text-lg font-semibold text-ab-100">Available for Grades {data.gradeLevel}</span>
						{data.schoolPaceConnectGrades && (
							<p className="font-bold">
								Available in SchoolPace Connect<sup>®</sup> for Grade {data.schoolPaceConnectGrades.map((grade) => grade)}
							</p>
						)}
					</Col>
					<Col width="w-full md:w-1/3" className="flex justify-center">
						{data.researchCard ? (
							<GatsbyImage image={data.researchCard.gatsbyImageData} alt={`${data.name} Research Card`} />
						) : (
							<StaticImage src="../../static/images/utils/no-research-card.png" height={400} placeholder="blurred" alt={`${data.name} Research Card`} />
						)}
					</Col>
				</Row>
			</Section>
			<Section gray>
				<SectionTitle
					title="Thematic Research Library"
					text="Students use the Thematic Research Library to research their individual topics using the best informational books drawn from more than 250 publishers."
				/>
				<Row>
					{data.leveledLibrary ? (
						data.leveledLibrary.slice(0, 5).map((basket) => {
							return (
								<Col width="w-1/5" gutter="none" key={basket.id}>
									<GatsbyImage image={basket.gatsbyImageData} alt={basket.title} />
								</Col>
							)
						})
					) : (
						<p>No Baskets for this Theme</p>
					)}
				</Row>
				<Row>
					<Col>
						<p className="mt-3 text-center text-xs">* All materials shown are a representative sample. Actual titles may vary based on availability.</p>
					</Col>
				</Row>
			</Section>
			<Section>
				<SectionTitle title="What's Included in this Module?" />
				<Row center>
					<Col width="w-full sm:w-5/6 md:w-3/4 lg:w-1/2 xl:w-5/12" className="mb-5">
						<div className="rounded border bg-white shadow-lg">
							<div className="flex flex-col px-10">
								<span className="my-4 text-center text-3xl font-medium">Text Set Module</span>
								<div className="mb-6 text-center text-lg text-slate-400">The Text Set Module includes the following:</div>
								<hr className="w-full" />
								<ul className="list-checkmark mx-auto my-8">
									<ModuleListItem text="Research Cards" subText="30 Student Copies" />
									<ModuleListItem text="Thematic Research Library" subText="100 Classroom Titles" />
								</ul>
							</div>
							<img src="/images/utils/blobs.svg" alt="card blobs" />
						</div>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-32">
				<Row id="priceForm" className="justify-center">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-7/12">
						<LargeForm
							pageName={`${data.name} Theme Page`}
							title="Looking for Pricing?"
							text="Fill out the form below and you'll get access to our pricing list to compare our different products."
							showPricingGuide
						/>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface Theme {
	name: string
	gradeLevel: string
	schoolPaceConnectGrades: string[]
	logo: { title: string; file: { url: string } }
	researchCard: { gatsbyImageData: IGatsbyImageData }
	frameworkCover: { gatsbyImageData: IGatsbyImageData }
	description: { description: string }
	coverImage: { file: { url: string } }
	leveledLibrary: {
		id: string
		title: string
		gatsbyImageData: IGatsbyImageData
	}[]
}

interface ThemeQuery {
	contentfulTheme: Theme
}

export const query = graphql`
	query ($slug: String!) {
		contentfulTheme(slug: { eq: $slug }) {
			name
			gradeLevel
			schoolPaceConnectGrades
			logo {
				file {
					url
				}
			}
			researchCard {
				gatsbyImageData(placeholder: BLURRED, height: 400)
			}
			frameworkCover {
				gatsbyImageData(placeholder: BLURRED)
			}
			description {
				description
			}
			coverImage {
				file {
					url
				}
			}
			leveledLibrary {
				id
				title
				gatsbyImageData(placeholder: BLURRED)
			}
		}
	}
`
