import React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
	title: string
	description: string
	image: string
}

const Head = ({ title, description, image }: Props) => {
	return (
		<Helmet title={title}>
			<meta property="og:title" content={title} />
			<meta name="description" content={description} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />
		</Helmet>
	)
}

export default Head
