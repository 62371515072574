import axios from 'axios'
import { Button } from '@arckit/ui'
import { Col, Row } from 'components/grid'
import { states } from '../../constants'
import { format } from 'date-fns'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import { useForm } from 'react-hook-form'
import catchError from 'utils/catchError'
import { Label, Select, TextArea, TextInput, ValidationError } from '../global/FormInputs'
import ContactInfo from './ContactInfo'
import ReCAPTCHA from 'react-google-recaptcha'
import { supabase } from 'clients/supabase'
import { P } from 'components/typography/Paragraph'
import { productOptions } from 'pages/connect'

interface Props {
	pageName: string
	button?: boolean
	navigateTo?: string
	showPricingGuide?: boolean
	showSummerPricingGuide?: boolean
	background?: string
	title?: string
	text?: string
	bottomContact?: boolean
}

interface FormState {
	first_name: string
	last_name: string
	email: string
	phone: string
	position: string
	city: string
	state: string
	zip_code: string
	district: string
	products: string[]
	comments: string
	isTest: boolean
}

const LargeForm = ({
	pageName,
	button,
	navigateTo = '/thank-you',
	showPricingGuide = false,
	showSummerPricingGuide = false,
	background = 'bg-slate-50',
	title = 'Want to Learn More?',
	text = 'Fill out the form below, and a representative from our team will contact you shortly!',
	bottomContact = true
}: Props) => {
	const initialState = {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		position: '',
		city: '',
		state: '',
		zip_code: '',
		district: '',
		products: [],
		comments: '',
		isTest: false
	}

	const [state, setState] = useState<FormState>(initialState)
	const [open, setOpen] = useState(button ? false : true)
	const [sending, setSending] = useState(false)
	const [notRobot, setNotRobot] = useState(false)
	const { register, errors, handleSubmit } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur'
	})

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		setState((prevState) => ({ ...prevState, [name]: value }))
	}

	function toggleString(arr: FormState['products'], value: string) {
		if (arr.includes(value)) {
			return arr.filter((item) => item !== value)
		} else {
			return [...arr, value]
		}
	}

	function handleSelectProduct(value: string) {
		setState((prevState) => ({
			...prevState,
			products: toggleString(prevState.products, value)
		}))
	}

	useEffect(() => {
		if (state.isTest) setNotRobot(true)
	}, [state.isTest])

	const submitForm = async () => {
		try {
			setSending(true)
			if (!state.isTest) {
				await supabase.from('form_submissions').insert({
					first_name: state.first_name,
					last_name: state.last_name,
					email: state.email,
					phone: state.phone,
					title: state.position,
					city: state.city,
					state: state.state,
					zip_code: state.zip_code,
					school_district: state.district,
					products: state.products,
					comments: state.comments,
					page: pageName
				})
				axios.post(`/api/email/new-connect-lead`, {
					contact: {
						firstName: state.first_name,
						lastName: state.last_name,
						email: state.email,
						phone: state.phone,
						position: state.position,
						city: state.city,
						state: state.state,
						zipCode: state.zip_code,
						district: state.district,
						products: state.products,
						comments: state.comments,
						pageName: pageName,
						submitDate: format(new Date(), 'MMM do yyyy, h:mm:ss a')
					}
				})
				axios.post(`/api/salesforce/capture-lead`, {
					contact: {
						firstName: state.first_name,
						lastName: state.last_name,
						email: state.email,
						phone: state.phone,
						position: state.position,
						city: state.city,
						state: state.state,
						zipCode: state.zip_code,
						district: state.district,
						comments: state.comments,
						pageName: pageName,
						submitDate: format(new Date(), 'MMM do yyyy, h:mm:ss a')
					}
				})
			}
			setSending(false)
			navigate(navigateTo, {
				state: {
					first_name: state.first_name,
					zip_code: state.zip_code,
					params: new URLSearchParams({ ...state, isTest: `${state.isTest}`, products: JSON.stringify(state.products) }).toString(),
					showPricingGuide: showPricingGuide,
					showSummerPricingGuide: showSummerPricingGuide
				}
			})
		} catch (error) {
			setSending(false)
			catchError(error)
		}
	}

	return (
		<div>
			<div className={`mb-6 rounded border p-8 shadow-lg ${background} `}>
				<span className="mb-2 block font-inter text-3xl font-extrabold">{title}</span>
				<P className="mb-6">{text}</P>
				{button && !open && (
					<Button data-cy="request-information-form-button" size="lg" onClick={() => setOpen(true)}>
						Request Information
					</Button>
				)}
				<Collapse
					isOpened={open}
					theme={{
						collapse: 'ReactCollapse--collapse transition-all duration-500 ease-in-out',
						content: 'ReactCollapse--content'
					}}
				>
					<form noValidate name={pageName} onSubmit={handleSubmit(submitForm)}>
						<Row>
							<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
								<Label name="First Name" htmlFor="first_name" required />
								<TextInput
									id="first_name"
									name="first_name"
									value={state.first_name}
									onChange={handleChange}
									type="text"
									required
									validation={register({
										required: 'First name is required',
										minLength: {
											value: 2,
											message: 'First name must be at least 2 characters'
										}
									})}
								/>
								<ValidationError>{errors.first_name && errors.first_name.message}</ValidationError>
							</Col>
							<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
								<Label name="Last Name" htmlFor="last_name" required />
								<TextInput
									id="last_name"
									name="last_name"
									value={state.last_name}
									onChange={handleChange}
									type="text"
									required
									validation={register({
										required: 'Last name is required',
										minLength: {
											value: 2,
											message: 'Last name must be at least 2 characters'
										}
									})}
								/>
								<ValidationError>{errors.last_name && errors.last_name.message}</ValidationError>
							</Col>
						</Row>
						<Row className="mb-6 border-b">
							<Col width="w-full sm:w-2/3" gutter="sm" className="mb-6">
								<Label name="Email" htmlFor="email" required />
								<TextInput
									id="email"
									name="email"
									value={state.email}
									onChange={handleChange}
									type="email"
									required
									validation={register({
										required: 'Email is required',
										pattern: {
											// eslint-disable-next-line
											value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: 'Please enter a valid email address'
										}
									})}
								/>
								<ValidationError>{errors.email && errors.email.message}</ValidationError>
							</Col>
							<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
								<Label name="Zip Code" htmlFor="zip_code" required />
								<TextInput
									id="zip_code"
									name="zip_code"
									value={state.zip_code}
									onChange={handleChange}
									type="text"
									required
									validation={register({
										required: 'Zip code is required',
										pattern: {
											value: /^\d{5}$/,
											message: 'Zip code must be 5 numbers'
										}
									})}
								/>
								<ValidationError>{errors.zip_code && errors.zip_code.message}</ValidationError>
							</Col>
						</Row>
						<Row>
							<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
								<Label name="Position" htmlFor="position" />
								<TextInput id="position" name="position" value={state.position} onChange={handleChange} type="text" />
							</Col>
							<Col width="w-full sm:w-1/2" gutter="sm" className="mb-6">
								<Label name="School/District" htmlFor="district" />
								<TextInput id="district" name="district" value={state.district} onChange={handleChange} type="text" />
							</Col>
							<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
								<Label name="Phone" htmlFor="phone" />
								<TextInput id="phone" name="phone" value={state.phone} onChange={handleChange} type="text" />
							</Col>
							<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
								<Label name="City" htmlFor="city" />
								<TextInput id="city" name="city" value={state.city} onChange={handleChange} type="text" />
							</Col>
							<Col width="w-full sm:w-1/3" gutter="sm" className="mb-6">
								<Label name="State" htmlFor="state" />
								<Select id="state" name="state" value={state.state} onChange={handleChange}>
									<option value=""></option>
									{states.map((state) => {
										return (
											<option key={state} value={state}>
												{state}
											</option>
										)
									})}
								</Select>
							</Col>
						</Row>
						<Row className="mb-2">
							<Col gutter="sm">
								<span className="text-ms mb-1 block font-semibold uppercase tracking-wide text-slate-800">Select the products you are interested in</span>
							</Col>
						</Row>
						<Row className="mb-4">
							{productOptions.map((p) => {
								return (
									<Col gutter="sm" key={`${p.name}-checkbox`} className="mb-2" width="w-1/3">
										<div className="flex items-center gap-2">
											<input
												onChange={(e) => handleSelectProduct(e.target.value)}
												id={`${p.name}-checkbox`}
												type="checkbox"
												value={p.name}
												checked={state.products.includes(p.name)}
											/>
											<label htmlFor={`${p.name}-checkbox`}>{p.name}</label>
										</div>
									</Col>
								)
							})}
						</Row>
						<Row>
							<Col gutter="sm" className="mb-6">
								<Label name="Comments" htmlFor="comments" />
								<TextArea id="comments" name="comments" rows="4" value={state.comments} onChange={handleChange} />
							</Col>
						</Row>
						<Row>
							<Col gutter="sm" className="mb-6 mt-2">
								<div className="mb-6">
									<ReCAPTCHA sitekey="6LdF0c4cAAAAAII8vYhSmG_RW6hpzB8PTeeDtSz-" onChange={() => setNotRobot(true)} />
								</div>
								<input
									aria-hidden="true"
									data-cy="isTest-checkbox"
									className="hidden"
									type="checkbox"
									onChange={() => setState((prevState) => ({ ...prevState, isTest: !state.isTest }))}
									checked={state.isTest}
									name="cy-test"
									id="cy-test"
								/>
								<Button data-cy="submit-button" loading={sending} size="lg" type="submit" disabled={sending || !notRobot}>
									Submit
								</Button>
							</Col>
						</Row>
					</form>

					{bottomContact && <ContactInfo />}
				</Collapse>
			</div>
		</div>
	)
}

export default LargeForm
