import React from 'react'

interface Props {
	text: string
	subText?: string
}

const ModuleListItem = ({ text, subText }: Props) => {
	return (
		<li className="pb-3">
			<span className="text-lg font-normal">{text}</span>
			<span className="block text-base text-slate-400">{subText}</span>
		</li>
	)
}

export default ModuleListItem
